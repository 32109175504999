import {talkService} from './index'

function getList(params) {
  return talkService.post('/bkd-user/list', params)
}

function getVipDetail(id) {
  return talkService.get('/bkd-user/vip/details?userId=' + id)
}

function getPublishList(id) {
  return talkService.get('/bkd-user/resource/list?userId=' + id)
}

function getVipRecord(id) {
  return talkService.get('/bkd-user/vip/list?userId=' + id)
}

function getUserDetail(id) {
  return talkService.get('/bkd-user/details?userId=' + id)
}

export default {
  getList,
  getVipDetail,
  getPublishList,
  getVipRecord,
  getUserDetail,
}