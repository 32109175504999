import {useEffect, useState} from 'react'
import ajax from '../ajax/user'
import './userInfo.scss'
import {Image} from 'antd'
import toast from '../components/toast'

export default function UserInfo({id, close}) {
  const [data, setData] = useState(null)
  const [visible, setVisible] = useState(false)

  useEffect(() => {
    ajax.getUserDetail(id).then(res => {
      if (res.success) {
        setData(res.data)
      }
    })
  }, [id])

  function showImg() {
    if (data && data.certificationUrl) {
      setVisible(true)
    } else {
      toast.warn('用户未上传认证图片')
    }
  }

  return (
    <div className="user-modal">
      <div className="user-panel">
        <div className="user-panel-head flex align info-head">
          <div className="close-wrap" onClick={close}>
            <div className="close"></div>
          </div>
          <div className="title f-600">用户个人资料信息</div>
        </div>
        {data && (
          <div className="user-info-body f-12">
            <div className="flex">
              <div className="left">
                <div className="avatar-wrap">
                  <div className="avatar" style={data.headUrl ? {backgroundImage: `url(${data.path + data.headUrl})`} : {}}></div>
                  <div className={`sex sex-${data.sex}`}></div>
                </div>
                <div className="link" onClick={showImg}>查看职位认证</div>
                <div className="gray">微信二维码</div>
                <div className="qr-code" style={data.codeUrl ? {backgroundImage: `url(${data.path + data.codeUrl})`} : {}}></div>
              </div>
              <div className="right">
                <div className="flex group-1">
                  <div className="flex group group-left">
                    <div className="label">用户名：</div>
                    <div className="f-600">{data.username}</div>
                  </div>
                  <div className="flex group">
                    <div className="label">真实姓名：</div>
                    <div className="f-600">{data.realName}</div>
                  </div>
                </div>
                <div className="flex group-2">
                  <div className="flex group group-left">
                    <div className="label">所在城市：</div>
                    <div className="f-600">{data.cityName}</div>
                  </div>
                  <div className="flex group">
                    <div className="label">手机号：</div>
                    <div className="f-600">{data.phone}</div>
                  </div>
                </div>
                <div className="line"></div>
                <div className="flex group-1">
                  <div className="flex group group-left">
                    <div className="label">公司全称：</div>
                    <div className="f-600">{data.enterpriseName}</div>
                  </div>
                  <div className="flex group">
                    <div className="label">手机号：</div>
                    <div className="f-600">{data.phoneNum || '未填写'}</div>
                    <div className="c-6"> · 项目对接</div>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex group group-left">
                    <div className="label">品牌：</div>
                    <div className="f-600">{data.brand}</div>
                  </div>
                  <div className="flex group">
                    <div className="label">邮箱：</div>
                    <div className="f-600">{data.email}</div>
                  </div>
                </div>
                <div className="flex">
                  <div className="flex group group-left">
                    <div className="label">职位：</div>
                    <div className="f-600">{data.job}</div>
                  </div>
                  <div className="flex group">
                    <div className="label">微信：</div>
                    <div className="f-600">{data.wechatNumber}</div>
                  </div>
                </div>
                <div className="flex group-2">
                  <div className="flex group group-left">
                    <div className="label">行业：</div>
                    <div className="f-600">{data.businessName}</div>
                  </div>
                </div>
                <div className="line"></div>
                <div className="flex group-3">
                  <div className="label">自我介绍：</div>
                  <div className="pre c-0">
                    {data.intro}
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      <Image
        style={{ display: 'none' }}
        preview={{
          visible,
          src: (data && data.certificationUrl) ? data.path + data.certificationUrl : '',
          onVisibleChange: value => {
            setVisible(value)
          },
        }}
      />
    </div>
  )
}