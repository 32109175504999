import {useRef, useEffect} from 'react'
import {useFastReducer} from '../components/hooks'
import ajax from '../ajax/login'
import './login.scss'
import toast from '../components/toast'
import {setUser, env} from '../components'
import {setAccessToken} from '../ajax'

let timer

export default function Login() {
  const codeRef = useRef()
  const cache = localStorage.getItem('phone') || ''
  const [state, dispatch] = useFastReducer({
    phone:  cache,
    phoneFocus: false,
    code: '',
    codeFocus: false,
    check: Boolean(cache),
    count: 0,
  })

  function nextStep(e) {
    const name = e.target.name
    if (e.key === 'Enter') {
      if (name === 'phone') {
        codeRef.current.focus()
      }
      if (name === 'code') {
        codeRef.current.blur()
        login()
      }
    }
  }

  function setFocus(e) {
    dispatch({[e.target.name + 'Focus']: true})
  }
  function setBlur(e) {
    dispatch({[e.target.name + 'Focus']: false})
  }
  function handleInput(e) {
    dispatch({[e.target.name]: e.target.value})
  }
  function setCheck() {
    dispatch({check: !state.check})
  }

  function sendCode() {
    if (state.phone.length !== 11) {
      toast.warn('请输入登录手机号')
      return
    }
    dispatch({count: 60})
    ajax.sendCode(state.phone).then(res => {
      if (res.data.success) {
        // do
      } else {
        clearTimeout(timer)
        dispatch({count: 0})
        toast.error(res.data.message)
      }
    })
  }

  function login() {
    if (state.phone.length !== 11) {
      toast.warn('请输入正确的手机号')
      return
    }
    if (state.code.length < 4) {
      toast.warn('请输入正确的验证码')
      return
    }
    if (state.check) {
      localStorage.setItem('phone', state.phone)
    } else {
      localStorage.setItem('phone', '')
    }
    ajax.login({phone: state.phone, code: state.code}).then(res => {
      if (res.data.success) {
        setUser(res.data.data)
        setAccessToken(res.data.data.accessToken)
        window.location.replace(env === 'test' ? '/talk-admin' : '/')
      } else {
        toast.error(res.data.message)
      }
    })
  }

  useEffect(function (){
    if (state.count > 0) {
      timer = setTimeout(() => {
        dispatch({count: state.count - 1})
      }, 1000)
    }
  }, [state.count])

  return (
    <div className="com-login">
      <div className="layout-section">
        {/*<div className="logo"></div>*/}
        <div className="bg"></div>
        <div className="caption">
          <div>Talk资源</div>
          <div className="f-600">内部管理OA系统</div>
        </div>
        <div className="login-panel">
          <div className="title-1 f-600">欢迎登录</div>
          <div className="title-2 f-500">WELCOME!</div>
          <div className={`input-wrap ${state.phoneFocus}`}>
            <div className="input-icon user"></div>
            <input
              type="number"
              autoComplete="off"
              name="phone"
              placeholder="请输入手机号码"
              value={state.phone}
              onFocus={setFocus}
              onBlur={setBlur}
              onChange={handleInput}
              onKeyDown={nextStep}
            />
          </div>
          <div className="check-wrap">
            <div className="flex align check-check" onClick={setCheck}>
              <div className={`check ${state.check}`}></div>
              <div>记住账号</div>
            </div>
          </div>
          <div className={`input-wrap ${state.codeFocus}`}>
            <div className="input-icon lock"></div>
            <input
              ref={codeRef}
              type="number"
              autoComplete="off"
              name="code"
              placeholder="请输入验证码"
              value={state.code}
              onFocus={setFocus}
              onBlur={setBlur}
              onChange={handleInput}
              onKeyDown={nextStep}
            />
            {state.count > 0 ? <div className="code-text">{state.count}s</div> : <div className="code-btn" onClick={sendCode}>发送验证码</div>}
          </div>
          <div className="login-btn f-500" onClick={login}>
            立即登录
          </div>
        </div>
      </div>
    </div>
  )
}