import { useFastReducer } from '../components/hooks'
import { Pagination, Image } from 'antd'
import { useEffect } from 'react'
import Breadcrumb from '../common/Breadcrumb'
import './demand.scss'
import ajax from "../ajax/audit"
import toast from '../components/toast'
import PublishList from "../user/PublishList"

const type = ['', '甲方需求', '异业合作', '项目合作', '地产专区']
const budgetType = ['详谈', '元', 'k', 'w']

export default function Demand({ setCount }) {
  const [state, dispatch] = useFastReducer({
    isPublish: null,
    page: 1,
    pageSize: 9,
    auditState: 0,//0.待审核 1.已审核 2.未通过
    list: [],
    checkAll: false,
    show: false,
    showSrc: [],
    waitNum: 0,
    alreadyNum: 0,
    notNum: 0,
  })
  function checkItem(index) {
    let list = [...state.list]
    list[index].check = !list[index].check
    let checkAll = list.every(item => item.check)
    dispatch({ list, checkAll })
  }
  function checkAll() {
    let checkAll = !state.checkAll
    let list = [...state.list]
    list.forEach(item => item.check = checkAll)
    dispatch({ list, checkAll })
  }
  function changeOpen(index) {
    let list = [...state.list]
    list[index].open = !list[index].open
    dispatch({ list })
  }
  function audit(type) {
    let checkList = state.list.filter(item => item.check).map(item => item.id)
    const params = {
      auditState: type,
      ids: checkList,
      type: 1
    }
    ajax.audit(params).then(res => {
      if (res.success) {
        toast.success('操作成功')
        getList()
      }
    })
  }
  function getList() {
    const params = {
      auditState: state.auditState,
      pageNum: state.page,
      pageSize: state.pageSize,
      type: 1//1.甲方需求 （异业）资源互换 项目合作 乙方服务 5.账号 6.留言
    }
    ajax.getAuditList(params).then(res => {
      if (res.success) {
        dispatch({
          list: res.data.records || [],
          total: res.data.total
        })
      }
    })
    getNum()
  }
  function getNum() {
    ajax.getAuditCount(1).then(res => {
      if (res.success) {
        dispatch({ ...res.data })
      }
    })
    setCount()
  }
  function showImg(src) {
    dispatch({ show: true, showSrc: src })
  }
  function showModel(item) {
    dispatch({ isPublish: { ...item, username: item.realName, id: item.userId } })
  }
  function closeModel() {
    dispatch({ isPublish: null })
  }

  useEffect(() => {
    getList()
  }, [state.page, state.auditState])
  return (
    <div className="com-content">
      <div className="demand-page">
        <Breadcrumb path="/audit/demand" />
        <div className='tab-wrap'>
          <div className={`tab ${state.auditState === 0}`} onClick={() => dispatch({ auditState: 0, list: [] })}>待审核·{state.waitNum}</div>
          <div className={`tab ${state.auditState === 1}`} onClick={() => dispatch({ auditState: 1, list: [] })}>已审核·{state.alreadyNum}</div>
          <div className={`tab ${state.auditState === 2}`} onClick={() => dispatch({ auditState: 2, list: [] })}>未通过·{state.notNum}</div>
        </div>
        <div className='table-wrap'>
          <div className='head-wrap'>
            <div className='col col-1'>ID/姓名</div>
            <div className='col col-2'>公司</div>
            <div className='col col-3'>职务</div>
            <div className='col col-4'>历史发布</div>
            <div className='col col-5'>需求标题</div>
            <div className='col col-6'>需求类型</div>
            <div className='col col-7'>类型</div>
            <div className='col col-8'>合作区域</div>
            <div className='col col-9'>合作预算</div>
            <div className='col col-10'>有效时间</div>
          </div>
          {state.list && state.list.map((item, index) =>
            <div className='row-wrap' key={index}>
              <div className='row'>
                <div className='col col-1'>
                  {state.auditState === 0 && <div className={`check ${!!item.check}`} onClick={() => checkItem(index)} />}
                  <div className='head-img' style={{ 'backgroundImage': `url(${item.path + item.headUrl})` }}></div>
                  <div>
                    <div>{item.number}</div>
                    <div className='username'>{item.realName}</div>
                  </div>
                </div>
                <div className='col col-2'>{item.enterpriseName}</div>
                <div className='col col-3'>{item.job}</div>
                <div className={`col col-4 ${item.publishNum>0}`} onClick={() => item.publishNum>0?showModel(item):null}>{item.publishNum}</div>
                <div className='col col-5'>
                  <div>
                    {item.title}
                    {item.resImageUrl?.length > 0 && <div className='img-icon' onClick={() => showImg(item.resImageUrl)} />}
                  </div>
                </div>
                <div className='col col-6'>
                  <div className={`tag tag-${item.type}`}>{type[item.type]}</div>
                </div>
                <div className='col col-7'>{item.typeStr?.join('/')}</div>
                <div className='col col-8'>{item.cityStr?.join('/')}</div>
                <div className='col col-9'>{item.budgetType > 0 ? '￥'+item.budget : ''}{budgetType[item.budgetType]}</div>
                <div className={`col col-10 ${!!item.open}`} onClick={() => changeOpen(index)}>{item.createTime}</div>
              </div>
              {item.open &&
                <div className='open-wrap'>
                  <div className='group'>
                    <div className='label'>我提供的资源：</div>
                    <div className='value'>{item.provideRes || '-'}</div>
                  </div>
                  {item.type > 2 &&
                    <div className='group'>
                      <div className='label'>我的项目情况：</div>
                      <div className='value'>{item.projectDes || '-'}</div>
                    </div>
                  }
                  <div className='group'>
                    <div className='label'>我需要的资源：</div>
                    <div className='value'>{item.needRes || '-'}</div>
                  </div>
                  <div className='group'>
                    <div className='label'>其他说明：</div>
                    <div className='value'>{item.otherDes || '-'}</div>
                  </div>
                </div>
              }
            </div>
          )}
        </div>
        {state.list && state.list.length > 0 && (
          <div className="pagination">
            <Pagination current={state.page} total={state.total} pageSize={state.pageSize} onChange={page => dispatch({ page })} />
          </div>
        )}
        {state.list?.length === 0 && <div className='empty-msg'>-暂无数据-</div>}
        {state.auditState === 0 && state.list && state.list.length > 0 &&
          <div className='audit-wrap'>
            <div className='check-wrap' onClick={checkAll}>
              <div className={`check ${state.checkAll}`} />
              <div>全选</div>
            </div>
            <div className='refuse' onClick={() => audit(2)}>不通过</div>
            <div className='pass' onClick={() => audit(1)}>通过</div>
          </div>
        }
        <Image.PreviewGroup
          items={state.showSrc}
          style={{ display: 'none' }}
          preview={{
            visible: state.show,
            onVisibleChange: (value) => dispatch({ show: value })
          }}
        >
          <Image />
        </Image.PreviewGroup>
        {state.isPublish && <PublishList title='历史发布' item={state.isPublish} close={closeModel} />}
      </div>
    </div>
  )
}