import { talkService } from './index'

// 获取oss config
function getOSSConfig() {
  return talkService.get('/oss')
}

function logout() {
  return talkService.get('/user/bkdLogout')
}

function getRedCount(){
  return talkService.get('/bkd/redDot')
}

export default {
  getOSSConfig,
  logout,
  getRedCount,
}