import {useEffect} from 'react'
import {useFastReducer} from '../components/hooks'
import UserLine from './UserLine'
import UserTable from './UserTable'
import {week} from '../components'
import dayjs from 'dayjs'
import {Pagination} from 'antd'
import ajax from '../ajax/dashboard'
import './dashboard.scss'

export default function Dashboard() {
  const [state, dispatch] = useFastReducer({
    date: '',
    page: 1,
    pageSize: 10,
    total: 0,
    list: null,
    count: {},
  })

  function getList() {
    ajax.getTableData({page: state.page, size: state.pageSize}).then(res => {
      if (res.success) {
        dispatch({
          list: res.data.records || [],
          total: res.data.total
        })
      }
    })
  }

  useEffect(() => {
    const now = dayjs().subtract(1, 'd')
    dispatch({date: `${now.month() + 1}月${now.date()}日 ${week(now.day())}`})
    ajax.getCount().then(res => {
      if (res.success) {
        dispatch({count: res.data})
      }
    })
  }, [])

  useEffect(() => {
    getList()
  }, [state.page])

  const c = state.count

  return (
    <div className="com-content">
      <div className="index-page">
        <div className="data-panel">
          <div className="flex align between data-head">
            <div className="flex align">
              <div className="dot"></div>
              <div className="green heavy">昨日</div>
              <div className="heavy c-0">新增数据 · {state.date}</div>
            </div>
            <div className="flex align">
              <div className="dot blue"></div>
              <div className="heavy c-0">实时总数据：</div>
              <div className="c-9">总用户</div>
              <div className="c-6 heavy">{c.totalUserCount || 0}</div>
              <div className="gap"></div>
              <div className="c-9">总会员</div>
              <div className="c-6 heavy">{c.totalVipCount || 0}</div>
              <div className="gap"></div>
              <div className="c-9">总金额</div>
              <div className="red heavy">￥{c.totalAmount || 0}</div>
            </div>
          </div>
          <div className="flex data-body">
            <div className="flex three-section">
              <div className="three">
                <div className="name-label label-1 heavy c-0">新增用户</div>
                <div className="flex number-wrap">
                  <div className="number c-0">{c.userCount || 0}</div>
                  <div className="unit f-12">个</div>
                </div>
                <div>
                  <div className="num-num">前日 · {c.yesterdayUserCount || 0}</div>
                  <div className="num-num">本周 · {c.weekUserCount || 0}</div>
                  <div className="num-num">本月 · {c.monthUserCount || 0}</div>
                </div>
              </div>
              <div className="three">
                <div className="name-label label-2 heavy c-0">新增会员</div>
                <div className="flex number-wrap">
                  <div className="number c-0">{c.vipCount || 0}</div>
                  <div className="unit f-12">个</div>
                </div>
                <div>
                  <div className="num-num">前日 · {c.yesterdayVipCount || 0}</div>
                  <div className="num-num">本周 · {c.weekVipCount || 0}</div>
                  <div className="num-num">本月 · {c.monthVipCount || 0}</div>
                </div>
              </div>
              <div className="three">
                <div className="name-label label-3 heavy c-0">新增营收</div>
                <div className="flex number-wrap">
                  <div className="unit f-12 red">￥</div>
                  <div className="number red">{c.amount || 0}</div>
                </div>
                <div>
                  <div className="num-num">前日 · ￥{c.yesterdayAmount || 0}</div>
                  <div className="num-num">本周 · ￥{c.weekAmount || 0}</div>
                  <div className="num-num">本月 · ￥{c.monthAmount || 0}</div>
                </div>
              </div>
            </div>
            <div className="flex four-section">
              <div className="four">
                <div className="name-label">
                  基础会员/<span className="heavy">季</span>
                </div>
                <div className="flex number-wrap">
                  <div className="number c-0">{c.vipLevelACount || 0}</div>
                  <div className="unit f-12">个</div>
                </div>
                <div>
                  <div className="num-num">前日 · {c.yesterdayVipLevelACount || 0}</div>
                  <div className="num-num">本周 · {c.weekVipLevelACount || 0}</div>
                  <div className="num-num">本月 · {c.monthVipLevelACount || 0}</div>
                </div>
              </div>
              <div className="four">
                <div className="name-label">
                  基础会员/<span className="heavy">半年</span>
                </div>
                <div className="flex number-wrap">
                  <div className="number c-0">{c.vipLevelBCount || 0}</div>
                  <div className="unit f-12">个</div>
                </div>
                <div>
                  <div className="num-num">前日 · {c.yesterdayVipLevelBCount || 0}</div>
                  <div className="num-num">本周 · {c.weekVipLevelBCount || 0}</div>
                  <div className="num-num">本月 · {c.monthVipLevelBCount || 0}</div>
                </div>
              </div>
              <div className="four">
                <div className="name-label">进阶会员</div>
                <div className="flex number-wrap">
                  <div className="number c-0">{c.vipLevelCCount || 0}</div>
                  <div className="unit f-12">个</div>
                </div>
                <div>
                  <div className="num-num">前日 · {c.yesterdayVipLevelCCount || 0}</div>
                  <div className="num-num">本周 · {c.weekVipLevelCCount || 0}</div>
                  <div className="num-num">本月 · {c.monthVipLevelCCount || 0}</div>
                </div>
              </div>
              <div className="four">
                <div className="name-label">甄选会员</div>
                <div className="flex number-wrap">
                  <div className="number c-0">{c.vipLevelDCount || 0}</div>
                  <div className="unit f-12">个</div>
                </div>
                <div>
                  <div className="num-num">前日 · {c.yesterdayVipLevelDCount || 0}</div>
                  <div className="num-num">本周 · {c.weekVipLevelDCount || 0}</div>
                  <div className="num-num">本月 · {c.monthVipLevelDCount || 0}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="line-table">
          <UserLine />
          <div className="heavy title-wrap">新增会员数据表</div>
          <UserTable list={state.list} />
          {state.list && state.list.length > 0 && (
            <div className="pagination">
              <Pagination current={state.page} total={state.total} pageSize={state.pageSize} onChange={page => dispatch({page})} />
            </div>
          )}
          {state.list && state.list.length === 0 && (
            <div className="empty-msg">
              - 暂无数据 -
            </div>
          )}
        </div>
      </div>
    </div>
  )
}