import { useEffect, useState } from 'react'
import { Outlet, useNavigate, useLocation } from 'react-router-dom'
import { user, defaultHead, setUser } from '../components'
import Login from './Login'
import { Popover } from 'antd'
import ajax from '../ajax/common'
import './layout.scss'

const routeName = {
  index: '首页',
  user: '用户管理',
  audit: '账号审核',
}

const auditRoute = {
  name: '审核管理',
  path: 'audit',
  count: 'auditNum',
  children: [
    { name: '注册审核', path: 'register', count: 'registerNum' },
    { name: '需求审核', path: 'demand', count: 'resourceNum' },
    { name: '留言审核', path: 'message', count: 'commentNum' },
  ]
}

export default function Layout({ count, setCount }) {
  // 菜单栏展开/收起
  const [expand, setExpand] = useState(true)
  if (!user) {
    return <Login />
  }

  const headUrl = (user.path + user.headUrl) || defaultHead

  const logout = (
    <div>
      <div className="logout-link hand" onClick={out}>退出登录</div>
    </div>
  )

  function out() {
    ajax.logout().then(res => {
      if (res.success) {
        setUser('')
        window.location.reload()
      }
    })
  }


  useEffect(() => {
    setCount()
  }, [])

  return (
    <div className="com-layout">
      <div className="com-header">
        <div className="logo"></div>
        <div className="nav-wrap">
          <Popover content={logout} trigger="click">
            <div className="nav-item">
              <div className="avatar" style={{ backgroundImage: `url(${headUrl})` }}></div>
              <div className="user-name f-600">{user.username}</div>
            </div>
          </Popover>
        </div>
      </div>
      <div className="com-wrap">
        <div className={`com-menu ${expand}`}>
          <div className={`toggle ${expand}`} onClick={() => setExpand(!expand)}></div>
          <MenuItem path="/" setCount={setCount}/>
          <MenuItem path="/user" icon="user" setCount={setCount}/>
          <MenuGroup value={auditRoute} pack={expand} setCount={setCount} count={count} />
        </div>
        <Outlet />
      </div>
    </div>
  )
}

// 单个一级菜单
function MenuItem({ path,setCount }) {
  const icon = path.split('/')[1] || 'index'
  const navigate = useNavigate()
  const location = useLocation()

  function go() {
    navigate(path)
    setCount()
  }

  const current = location.pathname.split('/')[1] || 'index'
  const active = current === icon

  return (
    <div className="menu-item">
      <div className={`menu ${active}`} onClick={go}>
        <div className={`icon ${icon}`}></div>
        <div className="name">{routeName[icon]}</div>
      </div>
    </div>
  )
}

// 菜单组
function MenuGroup({ value, pack, count ,setCount}) {
  const [expand, setExpand] = useState(false)
  const navigate = useNavigate()
  const location = useLocation()

  function toggle() {
    setExpand(!expand)
  }
  function go(path) {
    navigate(`/${value.path}/${path}`)
    // setCount()
  }

  const path = location.pathname.split('/')
  const active = path[1] === value.path
  const sub = path[2]

  return (
    <div className={`menu-item ${pack && expand}`}>
      <div className={`menu ${active}`} onClick={toggle}>
        <div className={`icon ${value.path}`}></div>
        <div className="name">{value.name}</div>
        <div className={`arrow ${active ? 'up' : 'down'}`}></div>
        {count[value.count] > 0 && <div className='red-dot'>{count[value.count]}</div>}
      </div>
      <div className="sub-menu">
        {value.children.map(item => (
          <div key={item.path} className={`sub-menu-item ${item.path === sub}`} onClick={() => go(item.path)}>
            {item.name}
            {count[item.count] > 0 && <div className='red-dot'>{count[item.count]}</div>}
          </div>
        ))}
      </div>
      <div className="hover-menu-wrap">
        <div className="hover-menu">
          {value.children.map(item => (
            <div key={item.path} className={`sub-menu-item ${item.path === sub}`} onClick={() => go(item.path)}>
              {item.name}
              {count[item.count] > 0 && <div className='red-dot'>{count[item.count]}</div>}
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}