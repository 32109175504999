import { useFastReducer } from '../components/hooks'
import { Pagination, Image } from 'antd'
import { useEffect } from 'react'
import Breadcrumb from '../common/Breadcrumb'
import './register.scss'
import ajax from "../ajax/audit"
import toast from '../components/toast'


export default function Register({ setCount }) {
  const [state, dispatch] = useFastReducer({
    page: 1,
    pageSize: 9,
    auditState: 0,//0.待审核 1.已审核 2.未通过
    list: [],
    checkAll: false,
    show: false,
    showSrc: '',
    waitNum: 0,
    alreadyNum: 0,
    notNum: 0,
  })

  function checkItem(index) {
    let list = [...state.list]
    list[index].check = !list[index].check
    let checkAll = list.every(item => item.check)
    dispatch({ list, checkAll })
  }
  function checkAll() {
    let checkAll = !state.checkAll
    let list = [...state.list]
    list.forEach(item => item.check = checkAll)
    dispatch({ list, checkAll })
  }
  function audit(type) {
    let checkList = state.list.filter(item => item.check).map(item => item.id)
    const params = {
      auditState: type,
      ids: checkList,
      type: 5
    }
    ajax.audit(params).then(res => {
      if (res.success) {
        toast.success('操作成功')
        getList()
      }
    })
  }
  function getList() {
    const params = {
      auditState: state.auditState,
      pageNum: state.page,
      pageSize: state.pageSize,
      type: 5//1.甲方需求 （异业）资源互换 项目合作 乙方服务 5.账号 6.留言
    }
    ajax.getAuditList(params).then(res => {
      if (res.success) {
        dispatch({
          list: res.data.records || [],
          total: res.data.total
        })
      }
    })
    getNum()
  }
  function getNum() {
    ajax.getAuditCount(5).then(res => {
      if (res.success) {
        dispatch({ ...res.data })
      }
    })
    setCount()
  }
  function showImg(src) {
    if (src) {
      dispatch({ show: true, showSrc: src })
    } else {
      toast.error('该用户未上传图片')
    }
  }

  useEffect(() => {
    getList()
  }, [state.page, state.auditState])

  return (
    <div className="com-content">
      <div className="register-page">
        <Breadcrumb path="/audit/register" />
        <div className='tab-wrap'>
          <div className={`tab ${state.auditState === 0}`} onClick={() => dispatch({ auditState: 0, list: [] })}>待审核·{state.waitNum}</div>
          <div className={`tab ${state.auditState === 1}`} onClick={() => dispatch({ auditState: 1, list: [] })}>已审核·{state.alreadyNum}</div>
          <div className={`tab ${state.auditState === 2}`} onClick={() => dispatch({ auditState: 2, list: [] })}>未通过·{state.notNum}</div>
        </div>
        <div className='list-wrap'>
          {state.list &&
            state.list.map((item, index) =>
              <div className={`card-wrap ${(index + 1) % 3 === 0}`} key={index}>
                {state.auditState === 0 && <div className={`check ${!!item.check}`} onClick={() => checkItem(index)} />}
                <div className='head-wrap'>
                  <div className='head-img'
                    style={{ "backgroundImage": `url(${item.path + item.headUrl})` }}
                    onClick={() => showImg(item.headUrl ? item.path + item.headUrl : '')}
                  >
                    <div className={`sex-icon sex-${item.sex}`}></div>
                  </div>
                  <div className='user-desc'>
                    <div className='flex align'>
                      <div className='user'>{item.realName}</div>
                      <div className='desc'>丨{item.job}</div>
                    </div>
                    <div className='flex align'>
                      <div className='desc'>{item.phone}</div>
                      <div className='btn' onClick={() => showImg(item.certificationUrl ? item.path + item.certificationUrl : '')}>
                        查看职位认证
                      </div>
                    </div>
                  </div>
                </div>
                <div className='msg-wrap'>
                  <div className='label'>公司：</div>
                  <div className='value'>{item.enterpriseName}</div>
                </div>
                <div className='info-wrap'>{item.intro}</div>
                <div className='time-wrap'>
                  <div>申请时间：</div>
                  <div>{item.createTime}</div>
                </div>
              </div>
            )
          }
          {state.list && state.list.length===0 && <div className='empty-msg full'>- 暂无数据 -</div>}
        </div>
        {state.list && state.list.length > 0 && (
          <div className="pagination">
            <Pagination current={state.page} total={state.total} pageSize={state.pageSize} onChange={page => dispatch({ page })} />
          </div>
        )}
        {state.auditState === 0 && state.list && state.list.length > 0 &&
          <div className='audit-wrap'>
            <div className='check-wrap' onClick={checkAll}>
              <div className={`check ${state.checkAll}`} />
              <div>全选</div>
            </div>
            <div className='refuse' onClick={() => audit(2)}>不通过</div>
            <div className='pass' onClick={() => audit(1)}>通过</div>
          </div>
        }
        <Image
          style={{ display: 'none' }}
          preview={{
            visible: state.show,
            src: state.showSrc,
            onVisibleChange: (value) => {
              dispatch({ show: value });
            },
          }}
        />
      </div>
    </div>
  )
}