import {useEffect, useState} from 'react'
import ajax from '../ajax/user'

const vipStr = ['', '基础会员/季度', '基础会员/半年', '进阶会员', '甄选会员']

export default function VipRecord({item, close}) {
  const [data, setData] = useState([])

  useEffect(() => {
    ajax.getVipRecord(item.id).then(res => {
      if (res.success) {
        setData(res.data)
      }
    })
  }, [item])

  return (
    <div className="user-modal">
      <div className="user-panel">
        <div className="user-panel-head">
          <div className="close-wrap" onClick={close}>
            <div className="close"></div>
          </div>
          <div className="flex align normal">
            <div>
              <div className="caption">
                <span className="text">历史会员{data.length > 0 ? ' · ' + data.length : ''}</span>
              </div>
              <div className="user-name">
                <span className="f-600">{item.username}</span>
                <span className="f-12 c-9"> | {item.job} · {item.enterpriseName}</span>
              </div>
            </div>
          </div>
          <div className="flex align table-head c-9 f-600">
            <div className="t-name">会员名称</div>
            <div className="t-price c-0">金额</div>
            <div className="t-date">购买时间：</div>
            <div>到期时间：</div>
          </div>
        </div>
        <div className="vip-record-body f-12">
          {data.map((it, i) => (
            <div key={i} className="vip-record flex align">
              <div className="t-name flex f-600">
                <div className={`t-level t-${it.vipLevel}`}>{vipStr[it.vipLevel]}</div>
              </div>
              <div className="t-price red f-600">
                <span>￥</span>
                <span className="f-16">{it.orderAmount}</span>
              </div>
              <div className="t-date">{it.createTime}</div>
              <div className="f-600">{it.expireTime}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}