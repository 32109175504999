export default function Breadcrumb({path, id}) {
  let name1 = '首页', name2 = ''
  const [,first, second, third] = path.split('/')

  if (first === 'user') {
    name1 = '用户管理'
  }

  if (first === 'audit') {
    name1 = '审核管理'
    if (second === 'register') {
      name2 = '注册审核'
    }
    if (second === 'demand') {
      name2 = '需求审核'
    }
    if (second === 'message') {
      name2 = '留言审核'
    }
  }

  return (
    <div className="breadcrumb-wrap">
      <div>{name1}</div>
      {name2 && (
        <div className="flex align">
          <div className="gap">-</div>
          <div className="light">{name2}</div>
        </div>
      )}
    </div>
  )
}