import { talkService } from './index'

function getCount() {
  return talkService.get('/data/count')
}

function getTableData(params) {
  return talkService.get('/data/list/count', { params })
}

function getLineChart(params) {
  return talkService.post('/data/line', params)
}

export default {
  getCount,
  getTableData,
  getLineChart,
}