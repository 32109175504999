import { talkService } from './index'

//各个审核列表数量
function getAuditCount(type) {
  return talkService.get('/bkd/auditNum?type=' + type)
}

//审核红点数量
function getRedDotNum() {
  return talkService.get('/bkd/redDot')
}

//审核列表
function getAuditList(params) {
  return talkService.post('/bkd/auditList', params)
}

//注册+留言+资源——审核操作
function audit(params) {
  return talkService.post('/bkd/audit', params)
}

export default {
  getAuditCount,
  getRedDotNum,
  getAuditList,
  audit,
}