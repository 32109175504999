import { loginService } from './index'

function login(params) {
  return loginService.post('/login/bkdLogin', params)
}

function sendCode(phoneNum) {
  return loginService.get('/login/send-code', { params: { phoneNum } })
}

export default {
  login,
  sendCode,
}