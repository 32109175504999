export default function UserTable({list}) {
  return (
    <table className="user-table">
      <thead>
        <tr className="table-head f-12">
          <th>日期</th>
          <th className="f-600">用户</th>
          <th className="f-600">会员</th>
          <th className="f-600 c-0">付费</th>
          <th>基础会员/季</th>
          <th>基础会员/半年</th>
          <th>进阶会员</th>
          <th>甄选会员</th>
          <th>甲方需求</th>
          <th>地产需求</th>
        </tr>
      </thead>
      {list && list.length > 0 && (
        <tbody className="table-body f-12">
        {list.map((it, i) => (
          <tr key={i} className="table-line">
            <td className="date">{it.date}</td>
            <td>{it.userCount}</td>
            <td>{it.vipCount}</td>
            <td className="red f-600">￥{it.vipAmount}</td>
            <td>{it.vipLevelACount}（￥{it.vipLevelAAmount}）</td>
            <td>{it.vipLevelBCount}（￥{it.vipLevelBAmount}）</td>
            <td>{it.vipLevelCCount}（￥{it.vipLevelCAmount}）</td>
            <td>{it.vipLevelDCount}（￥{it.vipLevelDAmount}）</td>
            <td>{it.resCount}</td>
            <td>{it.serviceResCount}</td>
          </tr>
        ))}
        </tbody>
      )}
    </table>
  )
}