import {useEffect, useState} from 'react'
import {replaceItemAtIndex} from '../components'
import ajax from '../ajax/user'

const typeStr = ['', '甲方需求', '异业合作', '项目合作', '乙方需求']

export default function PublishList({item, close,title}) {
  const [data, setData] = useState([])

  useEffect(() => {
    ajax.getPublishList(item.id).then(res => {
      if (res.success) {
        setData(res.data)
      }
    })
  }, [item])

  function toggleExpand(index) {
    let item = data[index]
    item = {...item, expand: !item.expand}
    setData(replaceItemAtIndex(data, index, item))
  }

  return (
    <div className="user-modal">
      <div className="user-panel">
        <div className="user-panel-head flex align normal">
          <div className="close-wrap" onClick={close}>
            <div className="close"></div>
          </div>
          <div>
            <div className="caption">
              <span className="text">{title||'已发布'}{data.length > 0 ? ' · ' + data.length : ''}</span>
            </div>
            <div className="user-name">
              <span className="f-600">{item.username}</span>
              <span className="f-12 c-9"> | {item.job} · {item.enterpriseName}</span>
            </div>
          </div>
        </div>
        <div className="publish-list-body f-12">
          {data.map((d, i) => (
            <div key={i} className={`publish-item-wrap ${!!d.expand}`}>
              <div className="publish-item" onClick={() => toggleExpand(i)}>
                <div className={`type-label type-${d.type}`}>{typeStr[d.type]}</div>
                <div className="title f-600 c-0">{d.title}</div>
                <div className="date c-6">| {d.publishTime}</div>
                <div className="price">
                  <div className="price-text">
                    <span>预算</span>
                    <span className="f-600">{budget(d)}</span>
                  </div>
                </div>
              </div>
              <div className="content-wrap">
                <div className="content">
                  <div className="f-600">我需要的资源</div>
                  <div className="pre">
                    {d.needRes}
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

function budget(input) {
  if (input.budgetType === 0) {
    return '详谈'
  }
  return '￥' + input.budget + ['', '', 'K', 'W'][input.budgetType]
}