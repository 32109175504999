const ossUrl = 'https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/talk_admin/'
const env = process.env.APP_ENV
const defaultHead = 'https://baitong-recruit-pro.oss-cn-chengdu.aliyuncs.com/front_end/reirc_web/login/default.jpg'

let user, userKey = 'talk-admin-user'
const temp = localStorage.getItem(userKey)
if (temp) {
  user = JSON.parse(temp)
}
function setUser(value) {
  user = value
  localStorage.setItem(userKey, JSON.stringify(value))
}

function replaceItemAtIndex(arr, index, newValue) {
  return [...arr.slice(0, index), newValue, ...arr.slice(index + 1)]
}

function removeItemAtIndex(arr, index) {
  return [...arr.slice(0, index), ...arr.slice(index + 1)]
}

function week(index) {
  return '星期' + ['日', '一', '二', '三', '四', '五', '六'][index]
}

export {
  ossUrl,
  env,
  user,
  defaultHead,
  setUser,
  replaceItemAtIndex,
  removeItemAtIndex,
  week,
}