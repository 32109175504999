import ajax from '../ajax/user'
import './vipInfo.scss'
import {useEffect, useState} from 'react'
const vipStr = ['', '基础会员/季度', '基础会员/半年', '进阶会员', '甄选会员']

function VipInfo({level, show}) {
  if (level > 0) {
    return (
      <div className={`vip-badge vip-${level}`} onClick={show}>
        {vipStr[level]}
      </div>
    )
  }
  return <div className="c-0">无</div>
}

function VipModal({level, id, close}) {
  const [data, setData] = useState({})
  useEffect(() => {
    ajax.getVipDetail(id).then(res => {
      if (res.success) {
        setData(res.data)
      }
    })
  }, [id])
  return (
    <div className="user-modal">
      <div className="user-panel">
        <div className="user-panel-head">
          <div className="close-wrap" onClick={close}>
            <div className="close"></div>
          </div>
          <div className="flex align between normal">
            <div>
              <div className="caption">
                <span className="text">{vipStr[level]}</span>
              </div>
              <div className="user-name">
                <span className="f-600">{data.username}</span>
                {data.job && <span className="f-12 c-9"> | {data.job} · {data.enterpriseName}</span>}
              </div>
            </div>
            <div className="vip-date">
              <div className="f-12 c-9">会员到期时间：</div>
              <div className="date f-12 f-600">{data.expireTime}</div>
            </div>
          </div>
          <div className="table-head flex">
            <div className="p-name c-9">产品名称</div>
            <div className="p-left c-0">剩余次数</div>
            <div className="c-9">总次数</div>
          </div>
        </div>
        <div className="vip-body f-12">
          {data.list && data.list.map((it, i) => (
            <div className="flex align vip-item" key={i}>
              <div className="p-name f-600 c-0">{it.name}</div>
              <div className="p-left green">{it.count}</div>
              <div className="c-0">{it.totalCount || '--'}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export {
  VipInfo,
  VipModal,
}