import './dashboard.scss'
import { useFastReducer } from '../components/hooks'
import { useRef, useEffect } from 'react'
import { Select, DatePicker } from 'antd'
import ajax from '../ajax/dashboard'
import dayjs from 'dayjs'

const { RangePicker } = DatePicker
let lineChart

export default function () {
  const chartRef = useRef()
  const [state, dispatch] = useFastReducer({
    data: [],
    type: [-1],
    dateType: 1,
    startTime: '',
    endTime: '',
    TypeList: [
      { label: '全部类型', value: -1 },
      { label: '非会员', value: 0 },
      { label: '基础/季', value: 1 },
      { label: '基础/半年', value: 2 },
      { label: '进阶会员', value: 3 },
      { label: '甄选会员', value: 4 },
    ],
    dateTypeList: [
      { label: '日趋势', value: 1 },
      { label: '周趋势', value: 2 },
      { label: '月趋势', value: 3 },
    ],
  })



  function handleChangeType(value) {
    dispatch({ type: value })
  }
  function handleChangeDateType(value) {
    let day = state.date ? state.date[1] : dayjs()
    let date = null, startTime = '', endTime = ''
    if (value === 1) {
      date = [day.subtract(6, 'day'), day]
    }
    if (value === 2) {
      date = [day.subtract(3, 'week'), day]
    }
    if (value === 3) {
      date = [day.subtract(11, 'month'), day]
    }
    startTime = date[0].format('YYYY-MM-DD')
    endTime = date[1].format('YYYY-MM-DD')
    dispatch({ dateType: value, date, startTime, endTime })
  }
  function onChangeDate(date) {
    let startTime = ''
    let endTime = ''
    if (date) {
      startTime = date[0].format('YYYY-MM-DD')
      endTime = date[1].format('YYYY-MM-DD')
    }
    dispatch({ date, startTime, endTime, })
  }
  function getData() {
    if (state.type.length > 0 && state.startTime) {
      const params = {
        timeType: state.dateType,
        vipTypes: state.type,
      }
      if (state.startTime) {
        params.startTime = state.startTime
        params.endTime = state.endTime
      }
      ajax.getLineChart(params).then(res => {
        if (res.success) {
          dispatch({ data: res.data })
        }
      })
    } else {
      dispatch({ data: [] })
    }
  }

  let res = new ResizeObserver(() => {
    lineChart.resize()
  })
  function refreshLine() {
    lineChart.resize()
  }

  useEffect(() => {
    let date, startTime, endTime
    date = [dayjs().subtract(6, 'day'), dayjs()]
    startTime = date[0].format('YYYY-MM-DD')
    endTime = date[1].format('YYYY-MM-DD')
    dispatch({ date, startTime, endTime })
    //
    lineChart = window.echarts.init(chartRef.current)
    window.addEventListener('resize', refreshLine)
    res.observe(chartRef.current)
    return () => {
      window.removeEventListener('resize', refreshLine)
    }
  }, [])

  useEffect(() => {
    getData()
  }, [state.startTime, state.type, state.dateType])


  const series = [], nameArr = []
  if (state.data) {
    //数据
    state.data.forEach(item => {
      let obj = {
        // smooth: true,
        name: item.key,
        type: 'line',
        data: item.list.map(item => item.num)
      }
      nameArr.push(item.key)
      series.push(obj)
    })
    if (lineChart) {
      lineChart.clear() //清除缓存数据
      const option = {
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          top: 10,
          left: '3%',
          data: nameArr
        },
        grid: {
          left: '3%',
          right: '4%',
          bottom: '3%',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          data: state.data[0]?.list.map(item => item.time),
          axisLabel: {
            color: '#B0ADD2',
          },
          axisLine: {
            lineStyle: {
              color: '#B0ADD2',
            }
          }
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            color: '#B0ADD2'
          }
        },
        series: series
      }
      //绘制
      lineChart.setOption(option)
    }
  }

  return (
    <div className="user-line">
      <div className="flex between title-wrap">
        <div className="heavy">用户趋势图</div>
        <div className="flex align">
          <div className="label f-12">用户类型</div>
          <Select
            mode="multiple"
            size='middle'
            value={state.type}
            placeholder="请选择"
            onChange={handleChangeType}
            style={{ width: 140, height: 32 }}
            options={state.TypeList}
          />
          <div className="label f-12">数据时间</div>
          <Select
            size='middle'
            value={state.dateType}
            placeholder="请选择"
            onChange={handleChangeDateType}
            style={{ width: 84, height: 32, marginRight: 5 }}
            options={state.dateTypeList}
          />
          {state.dateType === 1 && <RangePicker value={state.date} onChange={onChangeDate} />}
          {state.dateType === 2 && <RangePicker picker="week" value={state.date} onChange={onChangeDate} />}
          {state.dateType === 3 && <RangePicker picker="month" value={state.date} onChange={onChangeDate} />}
        </div>
      </div>
      <div ref={chartRef} className="line-chart"></div>
    </div>
  )
}