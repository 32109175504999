import ReactDOM from 'react-dom/client'
import App from './App'
import {ConfigProvider} from 'antd'
import zhCN from 'antd/locale/zh_CN'
import dayjs from 'dayjs'
import quarterOfYear from 'dayjs/plugin/quarterOfYear'
import 'dayjs/locale/zh-cn'

dayjs.locale('zh-cn')
dayjs.extend(quarterOfYear)

ReactDOM.createRoot(document.getElementById('app')).render(
  <ConfigProvider locale={zhCN}>
    <App />
  </ConfigProvider>
)
