import Breadcrumb from '../common/Breadcrumb'
import {useFastReducer} from '../components/hooks'
import './user.scss'
import toast from '../components/toast'
import {Pagination, Select} from 'antd'
import {VipInfo, VipModal} from './VipInfo'
import PublishList from './PublishList'
import VipRecord from './VipRecord'
import UserInfo from './UserInfo'
import {useEffect} from 'react'
import ajax from '../ajax/user'

export default function User() {
  const [state, dispatch] = useFastReducer({
    keyword: '',
    searchContent: '',
    userType: -1,
    isContact: false,
    userTypeList: [
      { label: '全部', value: -1 },
      { label: '非会员', value: 0 },
      { label: '基础/季度', value: 1 },
      { label: '基础/半年', value: 2 },
      { label: '进阶会员', value: 3 },
      { label: '甄选会员', value: 4 },
    ],
    page: 1,
    pageSize: 15,
    total: 0,
    list: null,
    isVip: 0, // 展示谁的vip面板
    vipLevel: 0, // vip等级
    isPublish: null, // 已发布弹窗列表
    isRecord: null, // 会员开通记录
    isUser: 0, // 会员详情
  })

  function handleInput(e) {
    dispatch({keyword: e.target.value})
  }
  function enterToSearch(e) {
    if (e.key === 'Enter') {
      const keyword = e.target.value.trim()
      if (keyword) {
        dispatch({keyword, searchContent: keyword, page: 1})
      } else {
        toast.warn('请输入搜索内容')
        dispatch({keyword})
      }
    }
  }
  function clickToSearch() {
    const keyword = state.keyword.trim()
    if (keyword) {
      dispatch({keyword, searchContent: keyword, page: 1})
    } else {
      toast.warn('请输入搜索内容')
      dispatch({keyword})
    }
  }
  function clearKeyword() {
    if (state.searchContent) {
      dispatch({keyword: '', searchContent: '', page: 1})
    } else {
      dispatch({keyword: ''})
    }
  }

  useEffect(() => {
    const params = {
      page: state.page,
      size: state.pageSize,
      vipLevel: state.userType,
    }
    if (state.searchContent) {
      params.searchContent = state.searchContent
    }
    ajax.getList(params).then(res => {
      if (res.success) {
        dispatch({list: res.data.records, total: res.data.total})
      }
    })
  }, [state.page, state.searchContent, state.userType])

  return (
    <div className="com-content">
      <div className="user-page">
        <Breadcrumb path="/user" />
        {/* 搜索栏 */}
        <div className="search-panel flex align">
          <div className="search-wrap">
            <input
              type="text"
              className="search-input"
              placeholder="搜索姓名/手机号/支付单号/会员编号"
              value={state.keyword}
              onChange={handleInput}
              onKeyDown={enterToSearch}
            />
            {state.keyword && (
              <div className="clear-btn" onClick={clearKeyword}>
                <div className="clear-icon"></div>
              </div>
            )}
            <div className="search-btn hand" onClick={clickToSearch}>
              <div className="search-icon"></div>
            </div>
          </div>
          <div className="select-wrap flex align">
            <div className="select-label blue-gray">用户类型</div>
            <Select
              value={state.userType}
              placeholder="请选择"
              options={state.userTypeList}
              onChange={v => dispatch({userType: v, page: 1})}
            />
          </div>
          <div className={`check-group flex align hand ${state.isContact}`} onClick={() => dispatch({isContact: !state.isContact})}>
            <div className="check"></div>
            <div className="f-600">查看联系方式</div>
          </div>
        </div>
        <div className="list-section">
          <div className="flex between">
            <div></div>
            <div className="f-12 blue-gray">合计：{state.total}位</div>
          </div>
          <table className="user-table f-12">
            <thead>
              <tr className="t-head">
                <th style={{width: '100px'}}>ID/姓名</th>
                <th align="left" className="pl-16">用户当前状态</th>
                <th align="left">公司</th>
                <th align="left">职务</th>
                <th>已发布</th>
                <th>历史会员</th>
                <th>历史消费</th>
                <th>注册时间</th>
                <th>首次会员时间</th>
                <th align="left">最后登陆</th>
              </tr>
            </thead>
            <tbody>
              {(state.list && state.list.length > 0) ? state.list.map((it, i) => (
                <tr key={i} className="t-line">
                  <td className={`name-td ${state.isContact}`} onClick={() => dispatch({isUser: it.id})}>
                    <div className="flex align name-wrap">
                      <div className="avatar" style={it.headUrl ? {backgroundImage: `url(${it.path + it.headUrl})`} : {}}></div>
                      <div>
                        <div className="no">{it.number || '--'}</div>
                        <div className="f-600">{it.username || '--'}</div>
                      </div>
                    </div>
                    <div className="pop">
                      <div className="phone-icon"></div>
                      <div>{it.phone}</div>
                    </div>
                  </td>
                  <td className="pl-16">
                    <VipInfo level={it.vipLevel} show={() => dispatch({isVip: it.id, vipLevel: it.vipLevel})} />
                  </td>
                  <td className="c-0">{it.enterpriseName || '--'}</td>
                  <td className="c-0">{it.job || '--'}</td>
                  <td align="center">
                    {it.resourceCount ? <div className="link hand" onClick={() => dispatch({isPublish: it})}>{it.resourceCount || 0}</div> :
                      <div className="blue-gray">0</div>}
                  </td>
                  <td align="center">
                    {it.historyVipCount ? <div className="link hand" onClick={() => dispatch({isRecord: it})}>{it.historyVipCount || 0}</div> :
                      <div className="blue-gray">0</div>}
                  </td>
                  <td align="center" className="red">
                    <span className="f-12">￥</span>
                    <span className="f-16 f-600">{it.orderAmount}</span>
                  </td>
                  <td align="center" className="blue-gray">{it.createTime}</td>
                  <td align="center" className="blue-gray">{it.firstVipTime || '--'}</td>
                  <td className="blue-gray">{it.lastTime}</td>
                </tr>
              )) : (
                <tr className="t-line">
                  <td colSpan={10} align="center" className="blue-gray">
                    {state.list && state.list.length === 0 && '暂无数据'}
                  </td>
                </tr>
              )}
            </tbody>
          </table>
          {state.list && state.list.length > 0 && (
            <div className="pagination">
              <Pagination current={state.page} total={state.total} pageSize={state.pageSize} onChange={page => dispatch({page})} />
            </div>
          )}
        </div>
      </div>
      {state.isVip > 0 && <VipModal level={state.vipLevel} id={state.isVip} close={() => dispatch({isVip: 0})} />}
      {state.isUser > 0 && <UserInfo id={state.isUser} close={() => dispatch({isUser: 0})} />}
      {state.isPublish && <PublishList item={state.isPublish} close={() => dispatch({isPublish: null})} />}
      {state.isRecord && <VipRecord item={state.isRecord} close={() => dispatch({isRecord: null})} />}
    </div>
  )
}