import { useState } from 'react'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import { env } from './components'
import ajax from './ajax/common'

import Layout from './common/Layout'
import NoMatch from './common/NoMatch'
import Dashboard from './dashboard'
import User from './user'
import Register from './audit/register'
import Demand from './audit/demand'
import Message from './audit/message'

import './app.scss'

export default function App() {
  const [count, setCount] = useState({})

  function updateCount() {
    ajax.getRedCount().then(res => {
      if (res.success) {
        const data = res.data || {}
        let commentNum = data.commentNum || 0
        let registerNum = data.registerNum || 0
        let resourceNum = data.resourceNum || 0
        let auditNum = commentNum + registerNum + resourceNum
        setCount({
          commentNum,
          registerNum,
          resourceNum,
          auditNum,
        })
      }
    })
  }

  return (
    <BrowserRouter basename={env === 'test' ? '/talk-admin' : '/'}>
      <Routes>
        <Route
          path="/"
          element={<Layout count={count} setCount={updateCount} />}
        >
          <Route index element={<Dashboard />} />
          <Route path="user" element={<User />} />
          <Route path="audit/register" element={<Register setCount={updateCount} />} />
          <Route path="audit/demand" element={<Demand setCount={updateCount} />} />
          <Route path="audit/message" element={<Message setCount={updateCount} />} />
          <Route path="*" element={<NoMatch />} />
        </Route>
      </Routes>
    </BrowserRouter>
  )
}